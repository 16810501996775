import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerRecibos() {
    var url = ConfigAPI.baseURL + "receipts-erp" + Session.getToken();
    return instance.get(url);
  },
  mostrarRecibos(id) {
    var url = ConfigAPI.baseURL + "receipts-erp/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarRecibos(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "receipts-erp" + Session.getToken();
    return instance.post(url,params);
  },
  editarRecibos(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "receipts-erp/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarRecibos(id) {
    var url = ConfigAPI.baseURL + "receipts-erp/"+ id + Session.getToken();
    return instance.delete(url);
  },     
  
  filtrarRecibos(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "receipts-erp/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  filtrarRecibosTrashed(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "receipts-erp/filter/query-trashed" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },    
  
  imputarRecibos(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "receipts-erp/impute/sales" + Session.getToken();
    return instance.post(url,params);
  }, 
  desimputarRecibos(data) {
    var params = data
    var url = ConfigAPI.baseURL + "receipts-erp/disprove/sales" + Session.getToken();
    return instance.post(url,params);    
  },

  filtrarPendienteImputar(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "receipts-erp/filter/pendingImpute" + Session.getToken();
    return instance.post(url,params);
  },    

  obtenerVoucherById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/id" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerVoucherByCustomer(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/customer" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerPuntosVentas() {
    var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
    return instance.get(url);
  }, 
  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },    
  
  filtrarCurrentsAccountsBalance(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-currents-accounts/filter/query/total" + Session.getToken();
    return instance.post(url,params);
  },    

  aprobarRecibosTrashed(data) {
    var params = data
    var url = ConfigAPI.baseURL + "receipts-erp/trashed/approval" + Session.getToken();
    return instance.post(url,params);    
  },
  desaprobarRecibosTrashed(data) {
    var params = data
    var url = ConfigAPI.baseURL + "receipts-erp/trashed/disapprove" + Session.getToken();
    return instance.post(url,params);    
  }  
}

export default servicesAPI;
